import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import LandingImage from "../assets/landing-page-graphic-AI-Dog-Trainer.webp";
import Logo from "../assets/DogTrainerAI.png";
import { auth } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import "../styles.css"; // Import from src/

const isMobile = window.innerWidth < 768;

const MainScreen = () => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  const dogFacts = [
    "Dogs can understand up to 250 words and gestures!",
    "A dog’s sense of smell is 10,000 to 100,000 times stronger than ours.",
    "Dalmatians are born without spots—they develop them later!",
  ];

  const getDailyFact = () => {
    const today = new Date().getDate();
    return dogFacts[today % dogFacts.length];
  };

  return (
    <div className="container">
      <div className="content-wrapper">
        {/* Test Font */}

        {/* Hero Section */}
        <motion.div
          className="hero-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="hero-image-container">
            <div className="image-wrapper">
              <img
                src={LandingImage}
                alt="AI Dog Trainer"
                className="hero-image"
                loading="lazy"
                onError={(e) => console.log("Image load failed:", e.nativeEvent.error)}
              />
            </div>
          </div>
          <div className="hero-text-container">
            <motion.h1
              className="hero-title"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Train Your Dog like a Pro!
            </motion.h1>
            <p className="hero-subtitle">
              Fun, real-time coaching for a happier, well-behaved pup.
            </p>
            <button
              className={`button-blue ${isButtonHovered ? "button-blue-hover" : ""}`}
              onClick={() => navigate(currentUser ? "/skills" : "/onboarding")}
              onMouseEnter={() => setIsButtonHovered(true)}
              onMouseLeave={() => setIsButtonHovered(false)}
              onFocus={() => setIsButtonHovered(true)}
              aria-label="Start training your dog with Doglingo"
            >
              Start Training
            </button>
          </div>
        </motion.div>

        <div className="divider" />

        {/* How It Works Section */}
        <motion.div
          className="section-container"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <h2 className="section-title">How It Works</h2>
          <div className="card">
            <div className="step-number">
              <span className="step-number-text">1</span>
            </div>
            <p className="step-text">
              <strong>Select a Training Goal</strong> – Pick &quot;Sit&quot;, &quot;Stay&quot;, &quot;Leash Training&quot;.
            </p>
          </div>
          <div className="card">
            <div className="step-number">
              <span className="step-number-text">2</span>
            </div>
            <p className="step-text">
              <strong>Real-Time AI Coaching</strong> – Step-by-step guidance with feedback.
            </p>
          </div>
          <div className="card">
            <div className="step-number">
              <span className="step-number-text">3</span>
            </div>
            <p className="step-text">
              <strong>Track Progress & Earn Rewards</strong> – Celebrate your dog&apos;s milestones.
            </p>
          </div>
        </motion.div>

        <div className="divider" />

        {/* Real-Time Training Section */}
        <motion.div
          className="section-container"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <div className={isMobile ? "section-row-mobile" : "section-row"}>
            <div className="section-icon-container">
              <motion.div
                className="section-icon-circle"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <span className="section-icon">🤖</span>
              </motion.div>
            </div>
            <div className="section-text-container">
              <h2 className="section-title">Real-Time Training</h2>
              <p className="section-text">
                Get instant feedback and guidance while you train — no more guessing if you&apos;re doing it right.
              </p>
            </div>
          </div>
        </motion.div>

        <div className="divider" />

        {/* Fun and Engaging Section */}
        <motion.div
          className="section-container"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <div className={isMobile ? "section-row-mobile" : "section-row"}>
            <div className="section-icon-container">
              <motion.div
                className="section-icon-circle"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <span className="section-icon">🎯</span>
              </motion.div>
            </div>
            <div className="section-text-container">
              <h2 className="section-title">Fun and Engaging</h2>
              <p className="section-text">
                Training shouldn&apos;t feel like a chore. Doglingo makes learning fun with games and rewards.
              </p>
            </div>
          </div>
        </motion.div>

        <div className="divider" />

        {/* Personalized for Your Dog Section */}
        <motion.div
          className="section-container"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <div className={isMobile ? "section-row-mobile" : "section-row"}>
            <div className="section-icon-container">
              <motion.div
                className="section-icon-circle"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <span className="section-icon">🐶</span>
              </motion.div>
            </div>
            <div className="section-text-container">
              <h2 className="section-title">Personalized for Your Dog</h2>
              <p className="section-text">
                Tailored training based on your dog’s age, breed, and personality.
              </p>
            </div>
          </div>
        </motion.div>

        <div className="divider" />

        {/* CTA Section */}
        <motion.div
          className="cta-container"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <div className="cta-text-container">
            <h3 className="cta-title">🐶 Ready to Train Smarter?</h3>
            <p className="cta-subtitle">
              Get a personalized plan with AI recommendations.
            </p>
          </div>
          <button
            className={`cta-button ${isButtonHovered ? "cta-button-hover" : ""}`}
            onClick={() => navigate(currentUser ? "/skills" : "/onboarding")}
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
            onFocus={() => setIsButtonHovered(true)}
            onBlur={() => setIsButtonHovered(false)}
            aria-label="Start training your dog with Doglingo"
          >
            Start Training
          </button>
        </motion.div>

        <div className="footer-divider" />

        {/* Footer */}
        <footer className="footer">
          <div className="footer-left">
            <img src={Logo} alt="Doglingo Logo" className="footer-logo" />
            <div className="social-icons">
              <a
                href="https://www.instagram.com/doglingoai/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
                aria-label="Follow Doglingo on Instagram"
              >
                <svg className="social-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61574414999269"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
                aria-label="Follow Doglingo on Facebook"
              >
                <svg className="social-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/doglingo/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
                aria-label="Follow Doglingo on LinkedIn"
              >
                <svg className="social-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                  <rect x="2" y="9" width="4" height="12" />
                  <circle cx="4" cy="4" r="2" />
                </svg>
              </a>
            </div>
          </div>
          <div className="footer-right">
            <a href="mailto:hello@doglingo.com" className="footer-link">Contact Us</a>
            <button onClick={() => navigate('/terms-of-service')} className="footer-link-button">
              <span className="footer-link">Terms of Service</span>
            </button>
            <button onClick={() => navigate('/privacy-policy')} className="footer-link-button">
              <span className="footer-link">Privacy Policy</span>
            </button>
          </div>
        </footer>

        {/* Bottom Bar with Fun Fact */}
        <div className="bottom-bar">
          <p className="dog-fact">Fun Fact: {getDailyFact()}</p>
        </div>
      </div>
    </div>
  );
};

export default MainScreen;