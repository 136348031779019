import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { auth, firestore } from "../firebase.js";
import { doc, getDoc } from "firebase/firestore";

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const [dogProfile, setDogProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    const fetchDogProfile = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error("User not authenticated");
        navigate("/login");
        return;
      }

      try {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("User data in SubscriptionSuccess:", JSON.stringify(userData, null, 2));
          const dogDocRef = doc(firestore, "users", user.uid, "dogs", "default");
          const dogDoc = await getDoc(dogDocRef);
          if (dogDoc.exists()) {
            setDogProfile(dogDoc.data());
          } else {
            setDogProfile({
              name: userData.dogName || "Your Dog",
              imageUrl: userData.dogImage || "https://via.placeholder.com/150"
            });
          }
        } else {
          console.error("User document not found in SubscriptionSuccess for UID:", user.uid);
        }
      } catch (error) {
        console.error("Error fetching data in SubscriptionSuccess:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDogProfile();
  }, [navigate, sessionId]);

  const handleStart = () => {
    navigate("/skills");
  };

  const getStyles = () => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#F7FAFC",
      padding: isMobile ? "15px" : "20px",
      width: "100%",
      boxSizing: "border-box"
    },
    contentWrapper: {
      backgroundColor: "#fff",
      padding: isMobile ? "20px" : "40px",
      borderRadius: "12px",
      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
      width: "100%",
      maxWidth: isMobile ? "100%" : "600px",
      textAlign: "center"
    },
    dogImage: {
      width: isMobile ? "120px" : "150px",
      height: isMobile ? "120px" : "150px",
      borderRadius: "50%",
      marginBottom: "20px",
      objectFit: "cover",
      boxShadow: "0 2px 8px rgba(0,0,0,0.1)"
    },
    dogName: {
      fontSize: isMobile ? "28px" : "32px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "15px"
    },
    heading: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: isMobile ? "32px" : "36px",
      fontWeight: "bold",
      color: "#007BFF",
      marginBottom: "30px",
      textAlign: "center"
    },
    startButton: {
      backgroundColor: "#007BFF",
      padding: isMobile ? "12px" : "15px",
      borderRadius: "50px",
      color: "#fff",
      fontSize: isMobile ? "16px" : "18px",
      fontWeight: "bold",
      cursor: "pointer",
      border: "none",
      width: isMobile ? "100%" : "200px",
      transition: "background-color 0.2s"
    },
    startButtonHover: {
      backgroundColor: "#0056b3"
    },
    loadingText: {
      fontSize: "18px",
      color: "#666",
      textAlign: "center"
    }
  });

  const styles = getStyles();

  if (loading) {
    return <p style={styles.loadingText}>Loading...</p>;
  }

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <img
          src={dogProfile?.imageUrl || "https://via.placeholder.com/150"}
          alt="Dog Profile"
          style={styles.dogImage}
        />
        <p style={styles.dogName}>{dogProfile?.name || "Your Dog"}</p>
        <h2 style={styles.heading}>Start Learning the Lingo!</h2>
        <button
          style={{ ...styles.startButton, ...(isHovered && styles.startButtonHover) }}
          onClick={handleStart}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Start
        </button>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;