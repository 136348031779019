import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../firebase.js";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import Logo from "../assets/DogTrainerAI.png";
import "../styles.css";

const SubscriptionScreen = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState("Weekly");
  const [donate, setDonate] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [timeLeft, setTimeLeft] = useState(600);
  const [specialOfferExpired, setSpecialOfferExpired] = useState(false);
  const [freeSkillClaimed, setFreeSkillClaimed] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const planOptions = {
    "Weekly": { id: "prod_S14rqGciWRjqIZ", price: "$6.99", original_price: "$13.98", days: 7 },
    "Monthly": { id: "prod_S14vT8ImXNXTyd", price: "$19.99", original_price: "$39.98", days: 30 },
    "Every 3 Months": { id: "prod_S14wPnpmsJrKql", price: "$29.99", original_price: "$59.98", days: 90 }
  };

  // Define pricing calculations
  const currentPlan = planOptions[selectedPlan];
  const basePrice = parseFloat(
    specialOfferExpired ? currentPlan.original_price.replace("$", "") : currentPlan.price.replace("$", "")
  );
  const finalPrice = discountedPrice !== null ? discountedPrice : basePrice;
  const totalPrice = donate ? finalPrice + 1 : finalPrice;

  useEffect(() => {
    setIsLogoAnimated(true);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFreeSkillClaimed(userData.freeSkillClaimed || false);
            setIsSubscribed(userData.isSubscribed || false);
            if (userData.isSubscribed) {
              console.log("User is subscribed, redirecting to /skills");
              navigate("/skills");
            }
          } else {
            console.warn("User document does not exist for UID:", user.uid);
          }
        } catch (err) {
          console.error("Error fetching user document:", err);
          setError("Failed to load user data");
        } finally {
          setLoading(false);
        }
      } else {
        console.log("No user authenticated, redirecting to login.");
        navigate("/login");
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (timeLeft > 0 && !specialOfferExpired) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft <= 0) {
      setSpecialOfferExpired(true);
    }
  }, [timeLeft, specialOfferExpired]);

  const validateCoupon = useCallback(async () => {
    if (!couponCode) {
      setDiscountedPrice(null);
      return;
    }

    const payload = { couponCode, planId: planOptions[selectedPlan].id };
    console.log("Sending /validateCoupon request:", JSON.stringify(payload));

    try {
      const response = await axios.post(
        "https://createcheckoutsession-wdspuzyvsq-uc.a.run.app/validateCoupon",
        payload,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data.discountedAmount !== undefined) {
        setDiscountedPrice(response.data.discountedAmount);
        setError(null);
      } else {
        setDiscountedPrice(null);
        setError("Invalid coupon code");
      }
    } catch (error) {
      console.error("Error validating coupon:", JSON.stringify(error.response?.data || error.message));
      setDiscountedPrice(null);
      setError(error.response?.data?.error || "Failed to validate coupon code");
    }
  }, [couponCode, selectedPlan]);

  useEffect(() => {
    validateCoupon();
  }, [couponCode, selectedPlan, validateCoupon]);

  const handleStartPlan = async () => {
    setLoading(true);
    setError(null);
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("User not authenticated");
        navigate("/login");
        setLoading(false);
        return;
      }

      const timerStart = new Date().toISOString();
      const timerDuration = 600;
      const payload = {
        userId: user.uid,
        plan: selectedPlan,
        timerStart: timerStart,
        timerDuration: timerDuration,
        donate: donate,
        couponCode: couponCode || null,
        successUrl: `${window.location.origin}/skills`,
        cancelUrl: `${window.location.origin}/subscription`
      };
      console.log("Sending /createCheckoutSession request:", JSON.stringify(payload));

      const response = await axios.post(
        "https://createcheckoutsession-wdspuzyvsq-uc.a.run.app/createCheckoutSession",
        payload,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data && response.data.checkoutUrl) {
        window.location.href = response.data.checkoutUrl;
      } else {
        setError("Failed to initiate payment. Please try again.");
        console.error("Stripe checkout session failed:", response.data);
      }
    } catch (error) {
      console.error("Error creating Stripe checkout session:", JSON.stringify(error.response?.data || error.message));
      setError(error.response?.data?.error || "An error occurred while connecting to Stripe. Please try again.");
      if (error.response && error.response.status === 404) {
        console.error("Cloud Function not found. Check deployment and URL.");
        setError("Payment service unavailable. Contact support.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFreeSkill = () => {
    if (freeSkillClaimed) {
      setShowPopup(true);
    } else {
      navigate("/skills");
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const toggleDonate = () => {
    setDonate((prev) => !prev);
  };

  // Define disclaimer functions
  const getDisclaimerText = () => {
    switch (selectedPlan) {
      case "Weekly":
        return `This is a subscription plan starting at $6.99 for the first week. After the promotional period ends, the price will increase to $13.98 every week.`;
      case "Monthly":
        return `This is a subscription plan starting at $19.99 for the first month. After the promotional period ends, the price will increase to $39.98 every month.`;
      case "Every 3 Months":
        return `This is a subscription plan starting at $29.99 for the first 3 months. After the promotional period ends, the price will increase to $59.98 every 3 months.`;
      default:
        return "";
    }
  };

  const getRecurringDisclaimer = () => {
    switch (selectedPlan) {
      case "Weekly":
        return "This is a recurring weekly subscription. You will be charged $13.98 every week after the initial period.";
      case "Monthly":
        return "This is a recurring monthly subscription. You will be charged $39.98 every month after the initial period.";
      case "Every 3 Months":
        return "This is a recurring quarterly subscription. You will be charged $59.98 every 3 months after the initial period.";
      default:
        return "";
    }
  };

  const mins = Math.floor(timeLeft / 60);
  const secs = timeLeft % 60;

  if (loading) return <div>Loading...</div>;
  if (isSubscribed) return null; // Shouldn’t render if redirected

  return (
    <div className="subscription-container">
      <div className="subscription-content-wrapper">
        <img src={Logo} className="subscription-logo" alt="Doglingo AI Logo" />
        <h1 className="subscription-main-heading">Learn the Doglingo!</h1>
        <div className="subscription-info-card">
          <h2 className="subscription-card-title">Did you know?</h2>
          <p className="subscription-card-text">
            Dogs can understand and grasp hundreds of phrases and signals, similar to a two-year-old child.
          </p>
        </div>

        <h2 className="subscription-plan-header">Choose Your Plan</h2>
        <div className="subscription-plan-container">
          <div className="subscription-plan-left">
            <div className="subscription-picker-container">
              <select
                value={selectedPlan}
                onChange={(e) => setSelectedPlan(e.target.value)}
                className="subscription-picker"
              >
                {Object.keys(planOptions).map((plan) => (
                  <option key={plan} value={plan}>
                    {plan}
                  </option>
                ))}
              </select>
              <div className="subscription-picker-caret"></div>
            </div>
            <input
              type="text"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              placeholder="Enter coupon code"
              className="subscription-coupon-input"
            />
            <button
              className="subscription-checkbox-container"
              onClick={toggleDonate}
              onKeyPress={(e) => e.key === 'Enter' && toggleDonate()}
              tabIndex={0}
            >
              <div className={`subscription-checkbox-square ${donate ? "subscription-checkbox-checked" : ""}`} />
              <span className="subscription-checkbox-text">❤️ Donate $1 to Dog Charities</span>
            </button>
          </div>
          <div className="subscription-plan-right">
            <div className="subscription-plan-details-card">
              <h3 className="subscription-plan-title">{selectedPlan}</h3>
              <p className="subscription-plan-price">
                {specialOfferExpired ? planOptions[selectedPlan].original_price : `🔥 Special Offer: ${planOptions[selectedPlan].price} 🔥`}
                {discountedPrice !== null && ` (Now $${discountedPrice.toFixed(2)} with coupon)`}
              </p>
              {!specialOfferExpired && (
                <p className="subscription-plan-original">Usually {planOptions[selectedPlan].original_price}</p>
              )}
              <p className="subscription-plan-per-day">Per Day: ${(finalPrice / planOptions[selectedPlan].days).toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div className="subscription-total-price-card">
          <p className="subscription-total-price-text">Total: ${totalPrice.toFixed(2)}</p>
          {donate && <p className="subscription-donation-note">Includes $1 donation</p>}
          {!specialOfferExpired && <p className="subscription-disclaimer-text">{getDisclaimerText()}</p>}
          {specialOfferExpired && <p className="subscription-recurring-disclaimer">{getRecurringDisclaimer()}</p>}
        </div>
        {error && <p className="subscription-error-text">{error}</p>}
        <button
          className={`subscription-start-button ${loading ? "disabled" : ""}`}
          onClick={handleStartPlan}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          disabled={loading}
        >
          {loading ? "Processing..." : "🚀 Start My Plan"}
        </button>
        <div className="subscription-divider" />

        <div className="subscription-cost-benefits-container">
          <div className="subscription-cost-card">
            <h2 className="subscription-card-title">Dog Training Costs in the USA</h2>
            <p className="subscription-card-text-left">💰 Average cost: $300-$2,000 per year</p>
            <p className="subscription-card-text-left">🏠 Group classes: $20-$75 per session</p>
            <p className="subscription-card-text-left">🐕 Private lessons: $50-$120 per hour</p>
            <p className="subscription-card-text-left">🏡 Boot camps: $500-$1,250 per week</p>
            <p className="subscription-card-text-left">✅ Our plans save you money & time!</p>
          </div>
          <div className="subscription-benefits-card">
            <h2 className="subscription-card-title">Benefits of Doglingo</h2>
            <p className="subscription-card-text-left">🐶 Tailored AI-Powered Training for Your Dog</p>
            <p className="subscription-card-text-left">⏱️ Master Commands in Just 10 Minutes Daily</p>
            <p className="subscription-card-text-left">💻 Unlock Over 50 Essential Skills & Commands</p>
            <p className="subscription-card-text-left">🏡 Train Anytime, Anywhere - No Gear Required</p>
            <p className="subscription-card-text-left">✅ Risk-Free with Our 100% Money-Back Guarantee</p>
          </div>
        </div>

        {!specialOfferExpired && timeLeft > 0 && (
          <div className="subscription-countdown-bar">
            <p className="subscription-countdown-text">
              🔥 Special Offer Expires in {mins}:{secs < 10 ? `0${secs}` : secs} 🔥
            </p>
          </div>
        )}
        {!freeSkillClaimed && (
          <button className="subscription-free-skill-link" onClick={handleFreeSkill}>
            I&apos;d like to try it out with one free skill first
          </button>
        )}
      </div>

      {showPopup && (
        <div className="subscription-popup-overlay">
          <div className="subscription-popup-content">
            <p className="subscription-popup-text">
              You&apos;ve already used your free skill! Would you like to view your skills or unlock all features?
            </p>
            <div className="subscription-popup-button-container">
              <button
                className="subscription-popup-button"
                onClick={() => navigate("/skills")}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Go to Skills
              </button>
              <button
                className="subscription-popup-button"
                onClick={closePopup}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                I Want All Skills
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionScreen;