import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase.js';
import Logo from "../assets/DogTrainerAI.png";

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    console.log('LoginScreen: Setting persistence and checking auth state...');
    const initializeAuth = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
        console.log('LoginScreen: Persistence set to LOCAL');
      } catch (err) {
        console.error('LoginScreen: Error setting persistence:', err.message);
      }

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        console.log('LoginScreen: onAuthStateChanged fired, user:', user ? user.uid : 'null');
        if (user) {
          console.log('LoginScreen: User logged in, navigating to SkillsScreen');
          navigate('/skills');
        }
        setIsLoading(false);
      });

      return () => {
        console.log('LoginScreen: Unsubscribing from auth state');
        unsubscribe();
      };
    };

    initializeAuth();
  }, [navigate]);

  const handleLogin = async () => {
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    console.log('LoginScreen: Attempting login with email:', trimmedEmail);

    if (!trimmedEmail || !trimmedPassword) {
      setError('Please enter both email and password.');
      console.log('LoginScreen: Missing email or password');
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, trimmedEmail, trimmedPassword);
      const user = userCredential.user;
      const idToken = await user.getIdToken();

      console.log('LoginScreen: Login successful, user:', user.uid);

      await setDoc(doc(firestore, 'sessions', user.uid), {
        user_email: trimmedEmail,
        id_token: idToken,
        local_id: user.uid,
        is_authenticated: true,
        lastLogin: new Date().toISOString()
      }, { merge: true });

      console.log('LoginScreen: Session data stored in Firestore');
      navigate('/skills'); // Navigate after successful login
    } catch (err) {
      console.error('LoginScreen: Login error:', err.code, err.message);
      if (err.code === 'auth/invalid-email' || err.code === 'auth/wrong-password' || err.code === 'auth/user-not-found') {
        setError('Invalid credentials, please try again.');
      } else if (err.code === 'auth/too-many-requests') {
        setError('Too many failed login attempts. Please try again later.');
      } else {
        setError(`An error occurred: ${err.message}`);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (isLoading) {
    return <p className="login-loading">Loading...</p>;
  }

  console.log('LoginScreen: Rendering login form');

  return (
    <div className="login-container">
      <div className="login-logo-container">
        <button className="login-logo-button" onClick={() => navigate('/')}>
          <img src={Logo} alt="Doglingo Logo" className="login-logo" />
        </button>
      </div>
      <h1 className="login-header">Login to Doglingo</h1>
      <div className="login-form-container">
        <input
          className="login-input"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
          autoCapitalize="none"
          type="email"
        />
        <div className="login-password-container">
          <input
            className="login-password-input"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            type={showPassword ? 'text' : 'password'}
          />
          <button
            className="login-eye-icon"
            onClick={toggleShowPassword}
          >
            <span>{showPassword ? '👁️' : '👁️‍🗨️'}</span>
          </button>
        </div>
        {error ? <p className="login-error">{error}</p> : null}
        <button
          className="login-button"
          onClick={handleLogin}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Login
        </button>
        <button className="login-link-button" onClick={() => navigate('/forgot-password')}>
          <p className="login-forgot-password">Forgot Password?</p>
        </button>
      </div>
      <button className="login-link-button" onClick={() => navigate('/onboarding')}>
        <p className="login-create-account">Create an Account</p>
      </button>
    </div>
  );
};

export default LoginScreen;