import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore, storage } from "../firebase.js";
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from "firebase/auth";
import { doc, setDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import Logo from "../assets/DogTrainerAI.png";
import { Link } from "react-router-dom";
import "../styles.css";

const questions = [
  { question: "What’s your dog’s name?", type: "text" },
  { question: "Upload a picture of your dog (optional)", type: "image" },
  { question: "Your dog is:", type: "radio", options: ["Girl", "Boy"] },
  { question: "Choose your dog's age:", type: "radio", options: ["Puppy (Less than a year old)", "Young (1 - 3 Years)", "Adult (3 - 7 Years)", "Senior (7+ Years)"] },
  { question: "What breed is your dog?", type: "text" },
  { 
    question: "What skills has your dog already learned?", 
    type: "multi", 
    options: [
      "Sit", "Stay", "Come", "Heel", "Shake", "Down", "Leave it",
      "Drop it", "Off", "Wait", "Watch me", "Fetch", "Roll over", "Speak", "Quiet", "Settle", "None"
    ]
  },
  { question: "Is your dog potty trained?", type: "radio", options: ["Yes", "No"] },
  { 
    question: "Any behavioral issues?", 
    type: "multi", 
    options: [
      "Aggression", "Separation Anxiety", "Excessive Barking", "Jumping", "Chewing",
      "Pulling on leash", "Counter-surfing", "Shyness", "None"
    ]
  },
  {
    question: "How active is your dog?",
    type: "radio",
    options: ["Very active (loves running)", "Moderately active", "Calm (prefers relaxing)"]
  },
  {
    question: "What’s your main training goal?",
    type: "radio",
    options: ["Obedience (e.g., sit, stay)", "Behavior (e.g., less barking)", "Fun tricks (e.g., roll over)", "Social skills (e.g., greeting politely)"]
  },
  { question: "Your Email:", type: "text" },
  { question: "Create a Password:", type: "password" }
];

const initialUserData = questions.reduce((acc, q) => {
  if (q.type === "radio" && q.options.length > 0) {
    acc[q.question] = q.options[0];
  } else if (q.type === "multi") {
    acc[q.question] = [];
  } else if (q.type === "image") {
    acc[q.question] = null;
  } else {
    acc[q.question] = "";
  }
  return acc;
}, {});

const OnboardingScreen = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [userData, setUserData] = useState(initialUserData);
  const [error, setError] = useState("");
  const [dogImage, setDogImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const maxSize = 5 * 1024 * 1024; // 5 MB limit
        if (file.size > maxSize) {
          setError("Image size exceeds 5 MB. Please upload a smaller image.");
          return;
        }
        if (dogImage) {
          URL.revokeObjectURL(dogImage);
        }
        const imageUrl = URL.createObjectURL(file);
        setDogImage(imageUrl);
        handleResponse(file);
      }
    },
  });

  useEffect(() => {
    setIsLogoAnimated(true);
    return () => {
      if (dogImage) {
        URL.revokeObjectURL(dogImage);
      }
    };
  }, [dogImage]);

  // Reset error and re-evaluate on step change
  useEffect(() => {
    setError(""); // Clear error when step changes
  }, [step]);

  const handleResponse = (response) => {
    const question = questions[step].question;
    let updatedResponse = response;

    if (question === "Any behavioral issues?" || question === "What skills has your dog already learned?") {
      if (Array.isArray(response)) {
        if (response.includes("None") && response.length > 1) {
          updatedResponse = ["None"];
        } else if (response.includes("None") && response.length === 1) {
          updatedResponse = ["None"];
        } else if (response.length > 0 && userData[question]?.includes("None")) {
          updatedResponse = response.filter((item) => item !== "None");
        }
      }
    }

    setUserData({ ...userData, [question]: updatedResponse });
    setError(""); // Clear error when a response is provided
  };

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidPassword = (password) => password.length >= 6;
  const isValidDogName = (name) => name.trim().length > 0 && name.trim().length <= 50;

  const canProceed = () => {
    const question = questions[step].question;
    const response = userData[question];

    if (question === "What’s your dog’s name?") {
      return isValidDogName(response);
    }
    if (question === "Upload a picture of your dog (optional)") {
      return true;
    }
    if (question === "What breed is your dog?") {
      return response.trim().length > 0;
    }
    if (question === "Your Email:") {
      return isValidEmail(response.trim());
    }
    if (question === "Create a Password:") {
      return isValidPassword(response);
    }
    if (questions[step].type === "multi") {
      return Array.isArray(response) && response.length > 0;
    }
    return response !== "";
  };

  const handleNext = async () => {
    const question = questions[step].question;
    const response = userData[question];

    if (!canProceed()) {
      if (question === "What’s your dog’s name?") {
        setError("Please enter a valid dog name (1-50 characters).");
      } else if (question === "What breed is your dog?") {
        setError("Please enter your dog’s breed.");
      } else if (question === "Your Email:") {
        setError("Please enter a valid email address.");
      } else if (question === "Create a Password:") {
        setError("Password must be at least 6 characters.");
      } else if (questions[step].type === "multi") {
        setError("Please select at least one option.");
      } else {
        setError("Please provide an answer.");
      }
      return;
    }

    if (question === "Your Email:") {
      const email = response.trim();
      try {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        if (signInMethods.length > 0) {
          setError(
            <div>
              <span>Email already in use.</span>
              <br />
              <button onClick={() => navigate("/login")} className="onboarding-login-link-button">
                <span className="onboarding-login-link">Would you like to login instead?</span>
              </button>
            </div>
          );
          return;
        }
      } catch (err) {
        console.error("Error checking email existence:", err);
        setError("Unable to verify email. Please try again later.");
        return;
      }
    }

    setError("");

    if (step === questions.length - 1) {
      const email = userData["Your Email:"].trim();
      const password = userData["Create a Password:"];

      try {
        console.log("Starting onboarding for email:", email);
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log("User created in Authentication:", user.uid);

        let imageUrl = null;
        if (dogImage && userData[questions[1].question]) {
          setUploading(true);
          try {
            console.log("Uploading image to Firebase Storage...");
            const file = userData[questions[1].question];
            const storageRef = ref(storage, `dogImages/${user.uid}/${file.name}`);
            await uploadBytes(storageRef, file);
            imageUrl = await getDownloadURL(storageRef);
            console.log("Image uploaded to Storage, download URL:", imageUrl);
          } catch (uploadErr) {
            console.error("Storage upload error:", uploadErr);
            setError("Failed to upload image. Please try again.");
            setUploading(false);
            return;
          }
          setUploading(false);
        } else {
          console.log("No image provided.");
        }

        const userDocRef = doc(firestore, "users", user.uid);
        const userDataToSave = {
          email,
          createdAt: new Date().toISOString(),
          freeSkillClaimed: false,
        };
        console.log("Attempting to save user data to Firestore:", userDataToSave);
        await setDoc(userDocRef, userDataToSave, { merge: true });
        console.log("User data successfully saved to Firestore at:", userDocRef.path);

        const dogDocRef = doc(collection(firestore, "users", user.uid, "dogs"));
        const dogData = {
          dogName: userData["What’s your dog’s name?"].trim(),
          dogGender: userData["Your dog is:"],
          dogAge: userData["Choose your dog's age:"],
          dogBreed: userData["What breed is your dog?"].trim(),
          skills: (userData["What skills has your dog already learned?"] || []).map(skill => skill.toLowerCase()),
          pottyTrained: userData["Is your dog potty trained?"],
          behavioralIssues: userData["Any behavioral issues?"] || [],
          activityLevel: userData["How active is your dog?"],
          trainingGoal: userData["What’s your main training goal?"],
          dogImage: imageUrl,
          createdAt: new Date().toISOString(),
        };
        console.log("Attempting to save dog data to Firestore:", dogData);
        await setDoc(dogDocRef, dogData);
        console.log("Dog data successfully saved to Firestore at:", dogDocRef.path);

        navigate("/dashboard");
      } catch (err) {
        console.error("Onboarding error:", {
          code: err.code || "unknown",
          message: err.message,
          stack: err.stack,
        });
        if (err.code === "auth/email-already-in-use") {
          setError(
            <div>
              <span>Email already in use.</span>
              <br />
              <button onClick={() => navigate("/login")} className="onboarding-login-link-button">
                <span className="onboarding-login-link">Would you like to login instead?</span>
              </button>
            </div>
          );
        } else if (err.code === "auth/network-request-failed") {
          setError("Network error. Please check your connection and try again.");
        } else if (err.code === "auth/invalid-email") {
          setError("Invalid email format. Please check your email.");
        } else {
          setError("Error registering: " + err.message);
        }
        setUploading(false);
      }
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && canProceed()) {
      handleNext();
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderQuestion = () => {
    const { question, type, options } = questions[step];
    if (type === "text") {
      return (
        <input
          className="onboarding-input"
          placeholder="Your answer"
          type={type}
          value={userData[question] || ""}
          onChange={(e) => handleResponse(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      );
    } else if (type === "password") {
      return (
        <div className="onboarding-password-container">
          <input
            className="onboarding-password-input"
            placeholder="Enter your password"
            type={showPassword ? "text" : "password"}
            value={userData[question] || ""}
            onChange={(e) => handleResponse(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button className="onboarding-eye-icon" onClick={toggleShowPassword}>
            <span>{showPassword ? "👁️" : "👁️‍🗨️"}</span>
          </button>
        </div>
      );
    } else if (type === "radio") {
      return (
        <div className="onboarding-radio-wrapper">
          {options.map((opt) => (
            <button
              key={opt}
              className="onboarding-radio-container"
              onClick={() => handleResponse(opt)}
              onKeyPress={(e) => e.key === "Enter" && handleResponse(opt)}
            >
              <div className={`onboarding-radio-circle ${userData[question] === opt ? "onboarding-radio-selected" : ""}`} />
              <span className="onboarding-radio-label">{opt}</span>
            </button>
          ))}
        </div>
      );
    } else if (type === "multi") {
      return (
        <div className="onboarding-radio-wrapper">
          {options.map((opt) => (
            <button
              key={opt}
              className="onboarding-radio-container"
              onClick={() => {
                const current = userData[question] || [];
                const newValue = current.includes(opt)
                  ? current.filter((item) => item !== opt)
                  : [...current, opt];
                handleResponse(newValue);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  const current = userData[question] || [];
                  const newValue = current.includes(opt)
                    ? current.filter((item) => item !== opt)
                    : [...current, opt];
                  handleResponse(newValue);
                }
              }}
            >
              <div className={`onboarding-radio-square ${userData[question]?.includes(opt) ? "onboarding-radio-selected" : ""}`} />
              <span className="onboarding-radio-label">{opt}</span>
            </button>
          ))}
        </div>
      );
    } else if (type === "image") {
      return (
        <div>
          <button
            {...getRootProps()}
            className="onboarding-image-picker"
            onKeyPress={(e) => e.key === "Enter" && getRootProps().onClick(e)}
          >
            <input {...getInputProps()} />
            <span className="onboarding-image-picker-text">📸 Upload a Picture (Drag or Click)</span>
          </button>
          {dogImage && <img src={dogImage} className="onboarding-dog-image-preview" alt="Dog Preview" />}
          {uploading && <p className="onboarding-uploading-text">Uploading...</p>}
        </div>
      );
    }
  };

  return (
    <div className="onboarding-container">
      <div className="onboarding-content-wrapper">
        <Link to="/" style={{ display: "block", textAlign: "center" }}>
          <img src={Logo} alt="Doglingo Logo" className="onboarding-logo" />
        </Link>

        <div className="onboarding-progress-bar">
          <div
            className="onboarding-progress-fill"
            style={{ width: `${((step + 1) / questions.length) * 100}%` }}
          />
        </div>
        <h2 className="onboarding-header">{questions[step].question}</h2>
        {renderQuestion()}
        {error && <span className="onboarding-error">{error}</span>}
        <div className="onboarding-button-container">
          {step > 0 && (
            <button
              className="onboarding-back-button"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onMouseDown={() => setClicked(true)}
              onMouseUp={() => setClicked(false)}
              onClick={handleBack}
            >
              Back
            </button>
          )}
          <button
            className={`onboarding-next-button ${!canProceed() || uploading ? "disabled" : ""}`}
            onClick={handleNext}
            disabled={!canProceed() || uploading} // Explicitly disable button
          >
            <span className="onboarding-button-text">{step === questions.length - 1 ? "Start" : "Next"}</span>
          </button>
        </div>

        {step === questions.length - 1 && (
          <div className="onboarding-footer-links">
            <p className="onboarding-agreement-text">
              By creating an account, you agree to our{" "}
              <button
                onClick={() => window.open("/privacy-policy", "_blank")}
                className="onboarding-footer-link-button"
              >
                <span className="onboarding-footer-link">Privacy Policy</span>
              </button>{" "}
              and{" "}
              <button
                onClick={() => window.open("/terms-of-service", "_blank")}
                className="onboarding-footer-link-button"
              >
                <span className="onboarding-footer-link">Terms of Service</span>
              </button>
              .
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingScreen;