import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { doc, setDoc, collection, getDocs } from "firebase/firestore";
import { auth, firestore } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";

const RateScreen = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("category");
  const skillId = searchParams.get("skill_id");
  const skillName = searchParams.get("skill_name");

  const [user, setUser] = useState(null);
  const [rating, setRating] = useState(null);
  const [dogs, setDogs] = useState([]);
  const [selectedDogId, setSelectedDogId] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [hoverStates, setHoverStates] = useState({
    rating_1: false,
    rating_2: false,
    rating_3: false,
    rating_4: false,
    rating_5: false,
    save: false,
  });

  const isMobile = window.innerWidth < 768;

  const ratings = {
    1: { icon: "⭐", label: "Not Learned" },
    2: { icon: "⭐⭐", label: "Some Progress" },
    3: { icon: "⭐⭐⭐", label: "Getting There" },
    4: { icon: "⭐⭐⭐⭐", label: "Almost Perfect" },
    5: { icon: "⭐⭐⭐⭐⭐", label: "Learned!" },
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        navigate("/login");
      } else {
        setUser(currentUser);
        if (!categoryId || !skillId || !skillName) {
          setError("Invalid skill selection. Please go back and choose a skill.");
          setLoading(false);
          return;
        }

        try {
          const dogsRef = collection(firestore, "users", currentUser.uid, "dogs");
          const dogsSnapshot = await getDocs(dogsRef);
          const dogsList = dogsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDogs(dogsList);
          if (dogsList.length > 0) {
            setSelectedDogId(dogsList[0].id);
          }
        } catch (err) {
          setError(`Failed to load dogs: ${err.message}`);
        } finally {
          setLoading(false);
        }
      }
    });
    return () => unsubscribe();
  }, [categoryId, skillId, skillName, navigate]);

  const saveRating = async () => {
    if (!rating) {
      setError("Please select a rating!");
      return;
    }
    if (!selectedDogId) {
      setError("Please select a dog!");
      return;
    }
    if (!user) {
      setError("User not authenticated!");
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const ratingData = {
        categoryId,
        skillId,
        skillName,
        rating,
        badgeEarned: rating === 5,
        timestamp: new Date().toISOString(),
      };

      const ratingRef = doc(firestore, "users", user.uid, "dogs", selectedDogId, "skillRatings", skillId);
      await setDoc(ratingRef, ratingData, { merge: true });

      setSuccess(`Progress saved! You rated ${skillName} as "${ratings[rating].label}"`);
      navigate("/skills"); // Navigate immediately
    } catch (err) {
      setError(`Failed to save rating: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "100vh",
      background: "linear-gradient(135deg, #f8fafc 0%, #e0e7ff 100%)",
      padding: isMobile ? "1rem" : "2rem",
      width: "100%",
      boxSizing: "border-box",
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "600px",
      padding: isMobile ? "1.5rem" : "2rem",
      background: "rgba(255, 255, 255, 0.95)",
      borderRadius: "16px",
      boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      boxSizing: "border-box",
    },
    heading: {
      fontFamily: "'MouseMemoirs', sans-serif",
      fontSize: isMobile ? "2rem" : "2.5rem",
      fontWeight: 700,
      color: "#1e3a8a",
      marginBottom: "1rem",
      textTransform: "uppercase",
      letterSpacing: "1.5px",
      textShadow: "0 2px 4px rgba(30, 58, 138, 0.1)",
    },
    subheading: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: isMobile ? "1rem" : "1.125rem",
      color: "#4b5563",
      marginBottom: "1.5rem",
    },
    selectContainer: {
      width: "100%",
      marginBottom: "1.5rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    selectLabel: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "1rem",
      color: "#4b5563",
      marginBottom: "0.5rem",
    },
    select: {
      width: "100%",
      maxWidth: "300px",
      padding: "0.75rem",
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "1rem",
      borderRadius: "8px",
      border: "1px solid #e2e8f0",
      backgroundColor: "#fff",
      outline: "none",
      transition: "border-color 0.3s ease, box-shadow 0.3s ease",
    },
    selectFocus: {
      borderColor: "#1e3a8a",
      boxShadow: "0 0 8px rgba(30, 58, 138, 0.2)",
    },
    ratingContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "0.75rem",
      marginBottom: "1.5rem",
      width: "100%",
      maxWidth: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    ratingButton: {
      width: "100%",
      padding: "1rem",
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
      textAlign: "center",
      borderRadius: "12px",
      border: "2px solid #e2e8f0",
      background: "linear-gradient(135deg, #ffffff, #e0e7ff)",
      cursor: "pointer",
      transition: "all 0.3s ease",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "0.25rem",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
    },
    ratingButtonSelected: {
      borderColor: "#f97316",
      background: "linear-gradient(135deg, #ffffff, #ffedd5)",
      boxShadow: "0 6px 18px rgba(249, 115, 22, 0.2)",
    },
    ratingButtonHover: {
      transform: "scale(1.05)",
      boxShadow: "0 6px 18px rgba(0, 0, 0, 0.1)",
      borderColor: "#1e3a8a",
    },
    saveButton: {
      padding: "0.75rem 1.5rem",
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "1.125rem",
      fontWeight: 600,
      background: "linear-gradient(135deg, #38a169, #16a34a)",
      color: "#fff",
      borderRadius: "50px",
      border: "none",
      cursor: "pointer",
      transition: "all 0.3s ease",
      width: isMobile ? "100%" : "auto",
      maxWidth: "300px",
    },
    saveButtonHover: {
      background: "linear-gradient(135deg, #16a34a, #15803d)",
      transform: "scale(1.05)",
      boxShadow: "0 4px 12px rgba(56, 161, 105, 0.3)",
    },
    saveButtonDisabled: {
      opacity: 0.7,
      pointerEvents: "none",
    },
    messageCard: {
      padding: "1rem",
      borderRadius: "12px",
      marginTop: "1rem",
      width: "100%",
      maxWidth: "300px",
      marginLeft: "auto",
      marginRight: "auto",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
    },
    success: {
      backgroundColor: "#d1fae5",
      color: "#38a169",
    },
    error: {
      backgroundColor: "#fee2e2",
      color: "#ef4444",
    },
    loadingText: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "1.125rem",
      color: "#1e3a8a",
      textAlign: "center",
      marginTop: "2rem",
    },
    noDogs: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "1rem",
      color: "#ef4444",
      marginBottom: "1.5rem",
    },
  };

  if (loading) {
    return <p style={styles.loadingText}>Loading...</p>;
  }

  if (error && !rating && !success) {
    return (
      <div style={styles.container}>
        <div style={styles.contentWrapper}>
          <p style={{ ...styles.messageCard, ...styles.error }}>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <h1 style={styles.heading}>{skillName}</h1>
        <p style={styles.subheading}>Rate how well your dog has learned this skill</p>

        {dogs.length > 0 ? (
          <div style={styles.selectContainer}>
            <label htmlFor="dogSelect" style={styles.selectLabel}>
              Select Dog
            </label>
            <select
              id="dogSelect"
              value={selectedDogId}
              onChange={(e) => setSelectedDogId(e.target.value)}
              style={styles.select}
              onFocus={(e) => (e.target.style = { ...styles.select, ...styles.selectFocus })}
              onBlur={(e) => (e.target.style = styles.select)}
            >
              {dogs.map((dog) => (
                <option key={dog.id} value={dog.id}>
                  {dog.dogName || "Unnamed Dog"}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <p style={styles.noDogs}>No dogs found. Please add a dog in your profile.</p>
        )}

        <div style={styles.ratingContainer}>
          {Object.entries(ratings).map(([value, { icon, label }]) => (
            <button
              key={value}
              style={{
                ...styles.ratingButton,
                ...(rating === parseInt(value) ? styles.ratingButtonSelected : {}),
                ...(hoverStates[`rating_${value}`] && rating !== parseInt(value) ? styles.ratingButtonHover : {}),
              }}
              onClick={() => setRating(parseInt(value))}
              onMouseEnter={() =>
                setHoverStates((prev) => ({ ...prev, [`rating_${value}`]: true }))
              }
              onMouseLeave={() =>
                setHoverStates((prev) => ({ ...prev, [`rating_${value}`]: false }))
              }
            >
              <span>{icon}</span>
              <span>{label}</span>
            </button>
          ))}
        </div>

        <button
          style={{
            ...styles.saveButton,
            ...(hoverStates.save ? styles.saveButtonHover : {}),
            ...(loading || !selectedDogId ? styles.saveButtonDisabled : {}),
          }}
          onClick={saveRating}
          onMouseEnter={() => setHoverStates((prev) => ({ ...prev, save: true }))}
          onMouseLeave={() => setHoverStates((prev) => ({ ...prev, save: false }))}
          disabled={loading || !selectedDogId}
        >
          Save Progress
        </button>

        {success && (
          <p style={{ ...styles.messageCard, ...styles.success }}>{success}</p>
        )}
        {error && <p style={{ ...styles.messageCard, ...styles.error }}>{error}</p>}
      </div>
    </div>
  );
};

export default RateScreen;